/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDimDocks30 = /* GraphQL */ `
  query GetDimDocks30($pk: String!, $sk: String!) {
    getDimDocks30(pk: $pk, sk: $sk) {
      OrganizationId
      OrganizationName
      OrganizationDescription
      OrganizationBranding
      IsOrganizationActive
      OrganizationUrl
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      OrganizationLogoUrl
      AssociatedUsers
      ProjectId
      ProjectName
      ProjectDescription
      IsProjectActive
      ProjectStartDate
      ProjectEndDate
      ServiceId
      ServiceName
      ServiceDescription
      ServiceUrl
      IsServiceActive
      ServiceFormTemplate
      ServiceTemplateValues
      AssociatedRoles
      RoleId
      RoleName
      RoleDescription
      IsRoleActive
      DoesServiceHaveChecks
      ServiceType
      PhaseId
      PhaseName
      IsPhaseActive
      SectorId
      SectorName
      IsSectorActive
      ClientId
      ClientName
      IsClientActive
      RegionId
      RegionName
      IsRegionActive
      CheckId
      CheckDescription
      CheckDataType
      CheckTemplateValue
      IsCheckActive
      CheckName
      NotificationId
      HasNotificationBeenRead
      NotificationMessage
      AssociatedSectors
      HasSupersededRoles
      UserId
      Username
      IsUserActive
      RegionDescription
      SectorDescription
      ClientDescription
      PhaseDescription
      RegionLatitude
      RegionLongitude
      PinnedOrganization
      LogoUrl
      LastActiveOrganization
      CheckFileType
      IsDeleted
      DIM360ImageMetadata
      DIM3DWebViewerMetadata
      pk
      sk
      __typename
    }
  }
`;
export const listDimDocks30s = /* GraphQL */ `
  query ListDimDocks30s(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        OrganizationId
        OrganizationName
        OrganizationDescription
        OrganizationBranding
        IsOrganizationActive
        OrganizationUrl
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        OrganizationLogoUrl
        AssociatedUsers
        ProjectId
        ProjectName
        ProjectDescription
        IsProjectActive
        ProjectStartDate
        ProjectEndDate
        ServiceId
        ServiceName
        ServiceDescription
        ServiceUrl
        IsServiceActive
        ServiceFormTemplate
        ServiceTemplateValues
        AssociatedRoles
        RoleId
        RoleName
        RoleDescription
        IsRoleActive
        DoesServiceHaveChecks
        ServiceType
        PhaseId
        PhaseName
        IsPhaseActive
        SectorId
        SectorName
        IsSectorActive
        ClientId
        ClientName
        IsClientActive
        RegionId
        RegionName
        IsRegionActive
        CheckId
        CheckDescription
        CheckDataType
        CheckTemplateValue
        IsCheckActive
        CheckName
        NotificationId
        HasNotificationBeenRead
        NotificationMessage
        AssociatedSectors
        HasSupersededRoles
        UserId
        Username
        IsUserActive
        RegionDescription
        SectorDescription
        ClientDescription
        PhaseDescription
        RegionLatitude
        RegionLongitude
        PinnedOrganization
        LogoUrl
        LastActiveOrganization
        CheckFileType
        IsDeleted
        DIM360ImageMetadata
        DIM3DWebViewerMetadata
        pk
        sk
        __typename
      }
      nextToken
      __typename
    }
  }
`;
